import styled from 'styled-components'
import Link from 'next/link'

import { FlexCol } from 'styles/styled'
import PrimaryButton from 'shared/components/mintButton/MintButton'
import { ServerErrorCont } from './500'

const NotFound = () => {
  return (
    <NotFoundCont>
      <FlexCol alignItems='center' gap='40px'>
        <FlexCol alignItems='center' gap='10px'>
          <h1>404</h1>
          <p>The page you were looking for does not exist.</p>
        </FlexCol>
        <Link href={'/'}>
          <PrimaryButton fillOpacity='1'>Go Home</PrimaryButton>
        </Link>
      </FlexCol>
    </NotFoundCont>
  )
}

export default NotFound

const NotFoundCont = styled(ServerErrorCont)``
