import Link from 'next/link'
import styled from 'styled-components'

import PrimaryButton from 'shared/components/mintButton/MintButton'
import { FlexCol } from 'styles/styled'
import { screenSizes } from 'styles/theme'

function Custom500() {
  return (
    <ServerErrorCont>
      <FlexCol alignItems='center' gap='40px'>
        <FlexCol alignItems='center' gap='10px'>
          <h1>500</h1>
          <p>Server-side error occurred</p>
        </FlexCol>
        <Link href={'/'}>
          <PrimaryButton fillOpacity='1'>Go Home</PrimaryButton>
        </Link>
      </FlexCol>
    </ServerErrorCont>
  )
}

export default Custom500

export const ServerErrorCont = styled.section`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  margin: 0 auto;

  h1 {
    font-family: var(--font-tomorrow);
    font-size: 60px;
  }
  p {
    font-family: var(--font-dm-mono);
    font-size: 10px;
  }

  a {
    &::after {
      background: none;
    }
  }

  @media (min-width: ${screenSizes.XS}px) {
    p {
      font-size: 16px;
    }
  }
`
